export class SaleTransaction {
  public walletAddress!: string | undefined;
  public transactionHash!: string;
  public ethAmount!: number;
  public dzyAmount!: number;

  constructor(values?: SaleTransaction) {
    Object.assign(this, values);
  }
}
