import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InputType } from 'src/app/enums/input-type';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() inputType: InputType = InputType.Default;
  @Input() confirmPassword = false;
  @Input() label = 'Label';
  @Input() placeholder = 'Placeholder';
  @Input() groupName!: FormGroup;
  @Input() controlName!: FormControl;
  @Output() modelChangeEvent = new EventEmitter<any>();

  InputType = InputType;

  getErrorMessage(): string {
    if (this.controlName?.hasError('required')) {
      return 'This field is required';
    }
    if (this.controlName?.hasError('email')) {
      return 'Not a valid email';
    }
    if (this.controlName?.hasError('minlength')) {
      return `Requires at least ${this.controlName?.getError('minlength').requiredLength} characters`;
    }
    if (this.controlName?.hasError('accountexist')) {
      return 'Account does not exist';
    }
    if (this.controlName?.hasError('passwordMatch')) {
      return 'Passwords do not match';
    }
    if (this.controlName?.hasError('min')) {
      return `Field has a minimal value of ${this.controlName?.getError('min').min}`;
    }
    if (this.groupName?.hasError('passwordMatch')) {
      return 'Passwords do not match';
    }
    return '';
  }
}
