import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterResult } from 'src/app/enums/register-result';

@Component({
  selector: 'app-register-result',
  templateUrl: './register-result.component.html',
  styleUrls: ['./register-result.component.scss']
})
export class RegisterResultComponent implements OnInit {
  externalId: string | null = null;
  registerResult: RegisterResult | null = null;
  success = new FormControl({ value: false, disabled: true });

  RegisterResult = RegisterResult;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const routeParam: string | null = this.route.snapshot.paramMap.get('result');
    if (routeParam != null) {
      const registerResult = Object.values(RegisterResult).find((x: string) => x.toLowerCase() === routeParam.toLowerCase());
      if (registerResult === RegisterResult.Success) {
        setTimeout(() => {
          this.success.setValue(true);
        }, 500);
      }
      // If url contains valid value use it, otherwise return user to register url
      registerResult != null ? (this.registerResult = registerResult) : this.router.navigateByUrl('/register');
    }
  }
}
