import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InputType } from 'src/app/enums/input-type';
import { PasswordReset } from 'src/app/models/password-reset/password-reset';
import { PasswordResetService } from 'src/app/services/password-reset.service';
import { confirmPasswordValidator } from 'src/app/validators/confirm-password-validator';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss']
})
export class ResetPasswordConfirmComponent implements OnInit {
  InputType = InputType;
  form!: FormGroup;
  password = new FormControl('', { validators: [Validators.required, Validators.minLength(8)] });
  confirmPassword = new FormControl('', { validators: [Validators.required, Validators.minLength(8)] });
  success = new FormControl({ value: false, disabled: true });

  loadingPage = true;
  invalid = false;
  error = false;
  showSuccess = false;
  loading = false;
  externalId = '';

  constructor(
    private route: ActivatedRoute,
    private passwordResetService: PasswordResetService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup(
      {
        password: this.password,
        confirmPassword: this.confirmPassword
      },
      { validators: [confirmPasswordValidator] }
    );

    this.externalId = this.route.snapshot.paramMap.get('externalId') ?? '';
    this.passwordResetService.verifypasswordReset(this.externalId).subscribe(result => {
      this.invalid = !result;
      this.loadingPage = false;
    });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.error = false;
      this.loading = true;

      const model = new PasswordReset({ externalId: this.externalId, newPassword: this.confirmPassword.value ?? '' });
      this.passwordResetService.passwordReset(model).subscribe(result => {
        if (result) {
          this.showSuccess = true;
          setTimeout(() => {
            this.success.setValue(true);
          }, 500);
        } else {
          this.error = true;
        }
        this.loading = false;
      });
    }
  }
}
