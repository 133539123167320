import { Component, OnInit, signal } from '@angular/core';
import { TransactionType } from 'src/app/enums/transaction-type';
import { DzyTokenHistory } from 'src/app/models/tokens/dzy-token-history';
import { TokensHistoryService } from 'src/app/services/tokens-history.service';

@Component({
  selector: 'app-tokens-history',
  templateUrl: './tokens-history.component.html',
  styleUrls: ['./tokens-history.component.scss']
})
export class TokensHistoryComponent implements OnInit {
  TransactionType = TransactionType;

  tokenHistory = signal<DzyTokenHistory[]>([]).asReadonly();
  tokenHistoryLoading = signal<boolean>(true).asReadonly();

  constructor(private tokenHistoryService: TokensHistoryService) {}

  ngOnInit(): void {
    this.tokenHistoryLoading = this.tokenHistoryService.loading;
    this.tokenHistory = this.tokenHistoryService.tokenHistory;

    this.tokenHistoryService.getTokenHistory();
  }
}
