<div class="mb-24" [ngClass]="{ 'input--error-bottom': controlName.invalid && (controlName.dirty || controlName.touched) }">
  <span class="input--label">{{ label }}</span>
  <input
    class="input"
    [type]="inputType"
    [formControl]="controlName"
    [placeholder]="placeholder"
    (ngModelChange)="modelChangeEvent.emit(controlName.value)"
    [ngClass]="{ 'input--error-styles': controlName.invalid && (controlName.dirty || controlName.touched) }" />
  <div *ngIf="!confirmPassword && controlName.invalid && (controlName.dirty || controlName.touched)" class="input--error">{{ getErrorMessage() }}</div>
  <div *ngIf="confirmPassword && groupName?.invalid && (controlName.dirty || controlName.touched)" class="input--error">{{ getErrorMessage() }}</div>
</div>
