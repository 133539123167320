import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputType } from 'src/app/enums/input-type';
import { TokenActionType } from 'src/app/enums/token-action-type';
import { environment } from 'src/environments/environment';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { mainnet, sepolia } from 'viem/chains';
import { Config, GetAccountReturnType, getAccount, reconnect, sendTransaction } from '@wagmi/core';
import { Web3Modal } from '@web3modal/wagmi/dist/types/src/client';
import { parseEther } from 'viem';
import { Environment } from 'src/app/enums/environment';
import { MatDialog } from '@angular/material/dialog';
import { SaleTransactionCompletedDialogComponent } from '../dialogs/sale-transaction-completed-dialog/sale-transaction-completed-dialog.component';
import { InputComponent } from '../../forms/input/input.component';

@Component({
  selector: 'app-token-sale',
  templateUrl: './token-sale.component.html',
  styleUrls: ['./token-sale.component.scss']
})
export class TokenSaleComponent implements OnInit {
  @ViewChild('dzyInput') dzyInput!: InputComponent;
  @ViewChild('ethInput') ethInput!: InputComponent;

  InputType = InputType;

  currentActionType = TokenActionType.Buy;
  contractAddressValue = environment.contractAddress;
  showCopied = false;

  form!: FormGroup;
  contractAddress = new FormControl({ value: this.contractAddressValue, disabled: true });
  draftzyAmount = new FormControl<number | null>(1000000, { validators: [Validators.required, Validators.min(10000)] });
  ethAmount = new FormControl<number | null>(0.1, { validators: [Validators.required, Validators.min(0.001)] });

  web3Modal!: Web3Modal;
  web3Config!: Config;
  web3Account!: GetAccountReturnType;
  web3TestNet = [sepolia] as const;
  web3MainNet = [mainnet] as const;

  constructor(private matDialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      draftzyAmount: this.draftzyAmount,
      ethAmount: this.ethAmount
    });

    await this.setupWalletConnect();
  }

  setTokenAmount(ethAmount: number): void {
    this.ethAmount.setValue(ethAmount);
    this.draftzyAmount.setValue(ethAmount * 10000000);
  }

  dzyChanged(value: any): void {
    this.ethAmount.setValue(Number((value / 10000000).toPrecision(9)), { emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: false });
  }

  ethChanged(value: any): void {
    this.draftzyAmount.setValue(Number((value * 10000000).toPrecision(9)), { emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: false });
  }

  setShowCopied(): void {
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 5000);
  }

  private async setupWalletConnect(): Promise<void> {
    const projectId = environment.walletConnectProjectId;
    const metadata = {
      name: 'DZY',
      description: 'Buy DZY tokens',
      url: environment.walletConnectDomain,
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    };

    const chains = environment.env === Environment.PROD ? this.web3MainNet : this.web3TestNet;
    this.web3Config = defaultWagmiConfig({
      chains,
      projectId,
      metadata
    });
    reconnect(this.web3Config);

    this.web3Modal = createWeb3Modal({
      wagmiConfig: this.web3Config,
      projectId,
      themeMode: 'light'
    });

    this.subscribeToWeb3ModalState();
  }

  public openWeb3Modal(): void {
    this.web3Modal.open();
  }

  private subscribeToWeb3ModalState(): void {
    this.web3Modal.subscribeState(async newState => {
      const account = getAccount(this.web3Config);
      const accountIsConnected = account?.isConnected;

      if (accountIsConnected && newState.open && this.ethAmount.value != null && this.ethAmount.value > 0) {
        const txHash = await sendTransaction(this.web3Config, {
          to: environment.contractAddress as `0x${string}`,
          value: parseEther(this.ethAmount.value.toString())
        });

        if (txHash != null) {
          this.web3Modal.close();
          this.matDialog.open(SaleTransactionCompletedDialogComponent, { data: txHash, panelClass: 'sale-transaction-completed-dialog' });
        }
      }
    });
  }
}
