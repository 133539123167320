import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { LeaguesPageComponent } from './pages/leagues-page/leagues-page.component';
import { TokensPageComponent } from './pages/tokens-page/tokens-page.component';
import { SupportPageComponent } from './pages/support-page/support-page.component';
import { RegisterVerifyPageComponent } from './pages/register-verify-page/register-verify-page.component';
import { RegisterResultPageComponent } from './pages/register-result-page/register-result-page.component';
import { authGuard } from './guards/auth.guard';
import { ResetPasswordConfirmPageComponent } from './pages/reset-password-confirm-page/reset-password-confirm-page.component';
import { SessionExpiredPageComponent } from './pages/session-expired-page/session-expired-page.component';
import { TokenSalePageComponent } from './pages/token-sale-page/token-sale-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'expired', component: SessionExpiredPageComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'register/:result', component: RegisterResultPageComponent },
  { path: 'register/verify/:externalId', component: RegisterVerifyPageComponent },
  { path: 'resetpassword', component: ResetPasswordPageComponent },
  { path: 'resetpassword/confirm/:externalId', component: ResetPasswordConfirmPageComponent },
  { path: 'tokensale', component: TokenSalePageComponent },
  { path: 'home', component: DashboardPageComponent, canActivate: [authGuard] },
  { path: 'leagues', component: LeaguesPageComponent, canActivate: [authGuard] },
  { path: 'tokens', component: TokensPageComponent, canActivate: [authGuard] },
  { path: 'support', component: SupportPageComponent, canActivate: [authGuard] },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
