import { Environment } from 'src/app/enums/environment';

export const environment = {
  env: Environment.PROD,
  baseUrl: '',
  registrationApiUrl: 'https://registration-api.draftzy.io',
  termsUrl: 'https://app.draftzy.io/assets/files/Terms-Conditions.pdf',
  privacyUrl: 'https://app.draftzy.io/assets/files/Privacy-Policy.pdf',
  contractAddress: '0x5F1890371cE89407CcdBdfFa4272F0121Eb3D3e3',
  walletConnectProjectId: '743a3af7ff57a323788411a811a76c42',
  walletConnectDomain: 'https://app.draftzy.io',
  etherscanUrl: 'https://etherscan.io/tx'
};
