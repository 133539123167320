import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-card-points',
  templateUrl: './dashboard-card-points.component.html',
  styleUrls: ['./dashboard-card-points.component.scss']
})
export class DashboardCardPointsComponent {
  fixtures = [
    {
      name: 'Fixture week 5',
      points: 5450
    },
    {
      name: 'Fixture week 4',
      points: 7610
    },
    {
      name: 'Fixture week 3',
      points: 8520
    },
    {
      name: 'Fixture week 2',
      points: 9270
    },
    {
      name: 'Fixture week 1',
      points: 8190
    }
  ];
}
