<form *ngIf="!loadingPage" #resetPasswordForm="ngForm" class="reset-password-container" [formGroup]="form">
  <div *ngIf="!invalid" class="reset-password-header mb-24">
    <h3 *ngIf="!showSuccess && !invalid">Setup new password</h3>
    <h3 *ngIf="showSuccess && !invalid">Password changed</h3>
    <span *ngIf="!showSuccess && !invalid" class="body-14">Please enter your new password below to finish your password reset</span>
    <span *ngIf="showSuccess && !invalid" class="body-14">Your password has been changed!</span>
  </div>

  <div *ngIf="!invalid" class="reset-password-body">
    <app-input *ngIf="!showSuccess" [controlName]="password" [label]="'New password'" [placeholder]="'********'" [inputType]="InputType.Password"></app-input>
    <app-input
      *ngIf="!showSuccess"
      [controlName]="confirmPassword"
      [label]="'Confirm password'"
      [placeholder]="'********'"
      [inputType]="InputType.Password"
      [groupName]="form"
      [confirmPassword]="true">
    </app-input>
    <app-checkbox *ngIf="showSuccess" class="checkbox-animate" [controlName]="success" [big]="true"></app-checkbox>
  </div>

  <div *ngIf="!invalid" class="reset-password-actions">
    <div class="reset-password-actions--reset">
      <button *ngIf="!showSuccess" class="button button--primary" [ngClass]="{ loading: loading }" type="button" (click)="submit()">
        <span *ngIf="!loading">Change password</span>
      </button>
      <span *ngIf="!showSuccess && error" class="error mt-12">Something went wrong, please try again</span>

      <button *ngIf="showSuccess" class="button button--primary" routerLink="/login">
        <span>Back to login</span>
      </button>
    </div>
  </div>

  <div *ngIf="invalid" class="reset-password-header mb-24">
    <h3 *ngIf="invalid">Password reset expired</h3>
    <span *ngIf="invalid" class="body-14">Password reset link has expired</span>
  </div>
  <div *ngIf="invalid" class="reset-password-body">
    <fa-icon class="checkbox-animate failed-icon" [icon]="['fas', 'circle-exclamation']"></fa-icon>
  </div>
  <div *ngIf="invalid" class="reset-password-actions">
    <div class="reset-password-actions--reset">
      <button class="button button--primary" routerLink="/login">
        <span>Go to login</span>
      </button>
    </div>
  </div>

  <div class="reset-password-footer">
    <span class="body-14 body-14--light">Already have an account?</span>
    <span routerLink="/login" class="text-button--primary">Log in</span>
  </div>
</form>
<div *ngIf="loadingPage" class="reset-password-container loading"></div>
