import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, of } from 'rxjs';
import { SaleTransaction } from '../models/sale-transaction/sale-transaction';

@Injectable({
  providedIn: 'root'
})
export class SaleTransactionService {
  constructor(private httpClient: HttpClient) {}

  addSaleTransaction(saleTransaction: SaleTransaction): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.registrationApiUrl}/saletransaction`, saleTransaction).pipe(
      catchError(() => of(false)),
      map(result => result)
    );
  }
}
