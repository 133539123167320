import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent {
  leagues = [
    {
      name: 'Premier League',
      playerCount: 55430,
      points: 589120
    },
    {
      name: 'LaLiga',
      playerCount: 52780,
      points: 342310
    },
    {
      name: 'Bundesliga',
      playerCount: 21800,
      points: 153090
    },
    {
      name: 'Serie A',
      playerCount: 18220,
      points: 294160
    },
    {
      name: 'Champions League',
      playerCount: 50870,
      points: 356140
    }
  ];
}
