import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PasswordResetRequest } from '../models/password-reset/password-reset-request';
import { PasswordReset } from '../models/password-reset/password-reset';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {
  constructor(private httpClient: HttpClient) {}

  requestPasswordReset(passwordResetRequest: PasswordResetRequest): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.registrationApiUrl}/resetpassword`, passwordResetRequest).pipe(
      catchError(() => of(false)),
      map(result => result)
    );
  }

  passwordReset(passwordReset: PasswordReset): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.registrationApiUrl}/resetpassword/reset`, passwordReset).pipe(
      catchError(() => of(false)),
      map(result => result)
    );
  }

  verifypasswordReset(externalId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.registrationApiUrl}/resetpassword/verify/${externalId}`).pipe(
      catchError(() => of(false)),
      map(result => result)
    );
  }
}
