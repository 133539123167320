import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { LoginUser } from '../models/login/login-user';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { JwtDraftzy } from '../models/login/jwt-draftzy';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public tokenObservable: Observable<string | null> = of(null);
  public usernameObservable: Observable<string | null> = of(null);
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private usernameSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.tokenSubject = new BehaviorSubject(localStorage.getItem('draftzy_token'));
    this.usernameSubject = new BehaviorSubject(localStorage.getItem('draftzy_username'));
    this.tokenObservable = this.tokenSubject.asObservable();
    this.usernameObservable = this.usernameSubject.asObservable();
  }

  public get token(): string | null {
    return this.tokenSubject.value;
  }

  public get decodedToken(): JwtDraftzy | null {
    return this.tokenSubject.value != null ? jwtDecode<JwtDraftzy>(this.tokenSubject.value) : null;
  }

  public get username(): string | null {
    return this.usernameSubject.value;
  }

  login(loginUser: LoginUser): Observable<string | null> {
    return this.httpClient.post<string>(`${environment.registrationApiUrl}/login`, loginUser).pipe(
      catchError(() => of(null)),
      map(result => {
        this.processTokenResult(result);
        return result;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('draftzy_token');
    localStorage.removeItem('draftzy_username');
    this.tokenSubject.next(null);
    this.usernameSubject.next(null);
    this.router.navigateByUrl('/login');
  }

  expireSession(): void {
    localStorage.removeItem('draftzy_token');
    localStorage.removeItem('draftzy_username');
    this.tokenSubject.next(null);
    this.usernameSubject.next(null);
    this.router.navigateByUrl('/expired');
  }

  validateToken(): Observable<boolean> {
    if (this.token != null && this.decodedToken?.exp != null) {
      // Check if token UTC time is after (in the future) the current UTC time. If it is, the token is still valid otherwise the token has expired
      const tokenExpUtc = moment.unix(Number(this.decodedToken?.exp)).utc();
      const nowUtc = moment().utc();

      const tokenTimeValid = tokenExpUtc.isAfter(nowUtc);
      if (tokenTimeValid) return of(true);

      return this.getNewToken();
    }

    // Default to invalid false result since no valid token is present/known
    return of(false);
  }

  isTokenTimeValid(): boolean {
    if (this.token != null && this.decodedToken?.exp != null) {
      // Check if token UTC time is after (in the future) the current UTC time. If it is, the token is still valid otherwise the token has expired
      const tokenExpUtc = moment.unix(Number(this.decodedToken?.exp)).utc();
      const nowUtc = moment().utc();
      return tokenExpUtc.isAfter(nowUtc);
    }

    return false;
  }

  getNewToken(): Observable<boolean> {
    return this.httpClient.get<string>(`${environment.registrationApiUrl}/token/new`).pipe(
      catchError(() => of(null)),
      map(result => {
        this.processTokenResult(result);
        return true;
      })
    );
  }

  private processTokenResult(result: string | null): void {
    if (result != null) {
      const username = jwtDecode<JwtDraftzy>(result)?.userUsername ?? '';
      localStorage.setItem('draftzy_token', result);
      localStorage.setItem('draftzy_username', username);
      this.tokenSubject.next(result);
      this.usernameSubject.next(username);
    } else {
      this.tokenSubject.next(null);
      this.usernameSubject.next(null);
    }
  }
}
