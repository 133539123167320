<div class="checkbox-container">
  <label class="checkbox path">
    <input class="input" type="checkbox" [formControl]="controlName" [ngClass]="{ 'input--no-label': label == null, 'checkbox-big': big }" />
    <svg viewBox="0 0 21 21" [ngClass]="{ 'checkbox-big': big }">
      <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
    </svg>
    <span *ngIf="label != null && !showsTermsAndPrivacy" class="body-14">{{ label }}</span>
    <div *ngIf="showsTermsAndPrivacy" class="body-14">
      <span>{{ label }}</span>
      <a href="{{ termsUrl }}" target="_blank">{{ termsLabel }}</a>
      <span> &</span>
      <a href="{{ privacyUrl }}" target="_blank">{{ privacyLabel }}</a>
    </div>
  </label>
  <div *ngIf="controlName?.invalid && (controlName?.dirty || controlName?.touched)" class="error error--checkbox">{{ getErrorMessage() }}</div>
</div>
