<div class="sidebar-container">
  <div class="sidebar--header" routerLink="/home">
    <img class="logo" src="assets/icons/draftzy-icon.svg" alt="T" />
    <span>Draftzy</span>
  </div>

  <div class="sidebar--body">
    <div class="sidebar--body--page" routerLink="/home" routerLinkActive="active">
      <div class="icon icon--home" routerLinkActive="icon--home--active"></div>
      <span>Dashboard</span>
    </div>
    <div class="sidebar--body--page" routerLink="/tokens" routerLinkActive="active">
      <div class="icon icon--tokens" routerLinkActive="icon--tokens--active"></div>
      <span>Tokens</span>
    </div>
    <div
      class="sidebar--body--page"
      routerLink="/leagues"
      routerLinkActive="active"
      #leagues="routerLinkActive"
      (mouseover)="leagueChevronDown = '--white'"
      (mouseout)="leagueChevronDown = leagues.isActive ? '--white' : '--primary-text'"
      (focus)="onFocus()"
      (blur)="onBlur()">
      <div class="icon icon--leagues" routerLinkActive="icon--leagues--active"></div>
      <span class="span--leagues">Leagues</span>
      <app-icon-chevron-down *ngIf="false" [color]="leagueChevronDown"></app-icon-chevron-down>
    </div>
    <div class="sidebar--body--page" routerLink="/support" routerLinkActive="active">
      <div class="icon icon--support" routerLinkActive="icon--support--active"></div>
      <span>Support</span>
    </div>
  </div>

  <div class="sidebar--footer">
    <div class="sidebar--footer--action" role="none" (click)="logout()" (keydown)="logout()">
      <div class="icon icon--logout"></div>
      <span>Log out</span>
    </div>
  </div>
</div>
