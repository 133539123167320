<app-coming-soon-card-overlay class="coming-soon-overlay z-index--1"></app-coming-soon-card-overlay>
<div class="dashboard-card-container">
  <div class="header-wrapper">
    <h2>Leaderboard</h2>
    <div class="league-wrapper">
      <div class="league-select">
        <span class="body-14 body-14--secondary">All</span>
        <fa-icon class="league-select--icon" [icon]="['fas', 'chevron-down']"></fa-icon>
      </div>
    </div>
  </div>

  <div *ngFor="let user of leaderboard; even as isEven; index as i">
    <div class="list-container" [ngClass]="{ 'bg--white-grey': isEven }">
      <div class="list-item">
        <span *ngIf="user.rank < 10" class="rank body-16 body-16--secondary" [ngClass]="{ 'color--accent-orange bold': i <= 2 }">0{{ user.rank }}</span>
        <span *ngIf="user.rank >= 10" class="rank body-16 body-16--secondary" [ngClass]="{ 'color--accent-orange bold': i <= 2 }">{{ user.rank }}</span>
        <span class="body-16 body-16--secondary name">{{ user.name }}</span>

        <div class="list-item--container">
          <div class="count-wrapper">
            <div class="count-wrapper--item">
              <img class="count-wrapper--item_img" src="assets/icons/draftzy-icon.svg" alt="T" />
              <span class="body-14 body-14--secondary bold">{{ user.points | shortNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-total-points-wrapper [text]="'Total points scored:'" [points]="75000"></app-total-points-wrapper>
</div>
