<form class="register-container" [formGroup]="form">
  <div class="register-header">
    <h3>Create an account</h3>
  </div>
  <div class="register-body">
    <div class="register-body--row">
      <app-input [controlName]="username" [label]="'Username'" [placeholder]="'JohnDoe'"></app-input>
    </div>
    <div class="register-body--row">
      <app-input [controlName]="email" [label]="'E-mail'" [placeholder]="'E-mail'"></app-input>
    </div>
    <div class="register-body--row">
      <app-input [controlName]="password" [label]="'Password'" [placeholder]="'********'" [inputType]="InputType.Password"></app-input>
    </div>
    <div class="register-body--row">
      <app-input [controlName]="confirmPassword" [label]="'Confirm password'" [placeholder]="'********'" [inputType]="InputType.Password" [groupName]="form" [confirmPassword]="true"></app-input>
    </div>
  </div>

  <div class="register-actions">
    <div class="register-actions--options">
      <div class="register-actions--options--terms">
        <app-checkbox [controlName]="terms" [label]="'I accept the'" [showsTermsAndPrivacy]="true"></app-checkbox>
      </div>
    </div>
    <div class="register-actions--register">
      <button class="button button--primary" [ngClass]="{ loading: loading }" type="button" (click)="submit()">
        <span *ngIf="!loading">Register</span>
      </button>
    </div>
  </div>

  <div class="register-footer">
    <span class="body-14 body-14--light">Already have an account?</span>
    <span routerLink="/login" class="text-button--primary">Log in</span>
  </div>
</form>
