<div class="tokens-card-container">
  <div class="tokens-card-wrapper">
    <div class="tokens-card-header">
      <div class="tokens-header">
        <h2>Token sale</h2>
      </div>
    </div>
    <div class="tokens-card-body">
      <div class="tokens-contract-wrapper">
        <div class="tokens-contract-header">
          <h2>Token contract address</h2>
          <span class="body-14">
            Purchase DZY tokens by safely connecting your wallet below, or send ETH to the contract address manually.<br />
            The contract will automatically send the correct amount of DZY tokens to your wallet.<br /><br />
            Connect your wallet safely and sign the transaction by clicking the connect wallet button at the bottom of the page.<br />
            Or copy the contract address by clicking the copy button below and send ETH to the contract address manually.
          </span>
        </div>
        <div class="tokens-contract-address">
          <app-input class="contract-input" [controlName]="contractAddress" [label]="'Token contract address'"></app-input>
          <fa-icon *ngIf="!showCopied" class="tokens-contract-address--icon" [icon]="['fas', 'copy']" [cdkCopyToClipboard]="contractAddressValue" (click)="setShowCopied()"></fa-icon>
          <div *ngIf="showCopied" class="copied">
            <fa-icon class="tokens-contract-address--icon tokens-contract-address--icon__success" [icon]="['fas', 'check']" [cdkCopyToClipboard]="contractAddressValue"></fa-icon>
            <span class="body-14 body-14--light">Copied</span>
          </div>
        </div>
      </div>
    </div>

    <div class="divider divider--p30"></div>

    <div class="tokens-card-amount-wrapper">
      <h2>Calculate token cost</h2>
      <div class="tokens-card-presets">
        <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === 0.1 }" (click)="setTokenAmount(0.1)" (keydown)="setTokenAmount(0.1)" role="none">1M for 0.1 ETH</div>
        <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === 1 }" (click)="setTokenAmount(1)" (keydown)="setTokenAmount(1)" role="none">10M for 1 ETH</div>
        <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === 5 }" (click)="setTokenAmount(5)" (keydown)="setTokenAmount(5)" role="none">50M for 5 ETH</div>
        <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === 10 }" (click)="setTokenAmount(10)" (keydown)="setTokenAmount(10)" role="none">100M for 10 ETH</div>
        <div class="preset body-14" [ngClass]="{ 'preset--active': ethAmount.value === 100 }" (click)="setTokenAmount(100)" (keydown)="setTokenAmount(100)" role="none">1B for 100 ETH</div>
      </div>
      <div class="tokens-card-amount">
        <app-input
          #dzyInput
          class="amount-input"
          [controlName]="draftzyAmount"
          (modelChangeEvent)="dzyChanged($event)"
          [label]="'Amount (DZY tokens)'"
          [placeholder]="'DZY amount'"
          [inputType]="InputType.Number"></app-input>
        <app-input
          #ethInput
          class="amount-input"
          [controlName]="ethAmount"
          (modelChangeEvent)="ethChanged($event)"
          [label]="'Amount (ETH)'"
          [placeholder]="'ETH amount'"
          [inputType]="InputType.Number"></app-input>
      </div>
    </div>

    <div class="divider divider--p30"></div>

    <div class="tokens-card-connect-wrapper">
      <div class="tokens-card-connect-amount">
        <span class="body-16">Total payment amount:</span>
        <span class="body-16 body-16--secondary bold">{{ ethAmount.value }} ETH</span>
      </div>
      <button class="button button--primary" type="button" (click)="openWeb3Modal()" [disabled]="form.invalid">
        <span>Connect wallet</span>
      </button>
    </div>
  </div>
</div>
