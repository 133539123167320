<div class="register-result-container">
  <div class="register-result-header mb-24">
    <h3 *ngIf="registerResult === RegisterResult.Success">Registration successful</h3>
    <h3 *ngIf="registerResult === RegisterResult.Failed">Registration has failed</h3>
    <span *ngIf="registerResult === RegisterResult.Success" class="body-14">You will receive an email to verify your registration. Verify your registration to start using Draftzy.</span>
    <span *ngIf="registerResult === RegisterResult.Failed" class="body-14">Something went wrong while trying to register, please try again later.</span>
  </div>
  <div class="register-result-body">
    <app-checkbox *ngIf="registerResult === RegisterResult.Success" class="checkbox-animate" [controlName]="success" [big]="true"></app-checkbox>
    <fa-icon *ngIf="registerResult === RegisterResult.Failed" class="checkbox-animate failed-icon" [icon]="['fas', 'circle-exclamation']"></fa-icon>
  </div>

  <div class="register-result-actions">
    <div class="register-result-actions--reset">
      <button *ngIf="registerResult === RegisterResult.Success" class="button button--primary" routerLink="/login">
        <span>Go to login</span>
      </button>

      <button *ngIf="registerResult === RegisterResult.Failed" class="button button--primary" routerLink="/register">
        <span>Go to register</span>
      </button>
    </div>
  </div>

  <div class="register-result-footer">
    <span class="body-14 body-14--light">Already a member?</span>
    <span routerLink="/login" class="text-button--primary">Go to login</span>
  </div>
</div>
