import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-chevron-down',
  templateUrl: './icon-chevron-down.component.html',
  styleUrls: ['./icon-chevron-down.component.scss']
})
export class IconChevronDownComponent {
  @Input() color = '--primary-text';
}
