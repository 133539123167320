import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  title = 'Draftzy';
  sidebarVisible = false;
  screenWidth = 0;
  headerVisible = false;
  nonLayoutRoutes = ['/login', '/resetpassword', '/register', '/expired', '/tokensale']; // Only works for single path URLs

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.sidebarVisible =
          !this.nonLayoutRoutes.includes(x.urlAfterRedirects.toLowerCase()) &&
          !x.urlAfterRedirects.toLowerCase().includes('/register/verify') &&
          !x.urlAfterRedirects.toLowerCase().includes('/register/success') &&
          !x.urlAfterRedirects.toLowerCase().includes('/register/failed') &&
          !x.urlAfterRedirects.toLowerCase().includes('/resetpassword/confirm');
        this.headerVisible =
          !this.nonLayoutRoutes.includes(x.urlAfterRedirects.toLowerCase()) &&
          !x.urlAfterRedirects.toLowerCase().includes('/register/verify') &&
          !x.urlAfterRedirects.toLowerCase().includes('/register/success') &&
          !x.urlAfterRedirects.toLowerCase().includes('/register/failed') &&
          !x.urlAfterRedirects.toLowerCase().includes('/resetpassword/confirm');
      }
    });
  }
}
