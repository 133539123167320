import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-card-leaderboard',
  templateUrl: './dashboard-card-leaderboard.component.html',
  styleUrls: ['./dashboard-card-leaderboard.component.scss']
})
export class DashboardCardLeaderboardComponent {
  leaderboard = [
    {
      name: 'John',
      points: 99010,
      rank: 1
    },
    {
      name: 'Craig',
      points: 87250,
      rank: 2
    },
    {
      name: 'Marty',
      points: 82760,
      rank: 3
    },
    {
      name: 'Lucy',
      points: 75380,
      rank: 4
    },
    {
      name: 'Felix',
      points: 61190,
      rank: 5
    }
  ];
}
