<div class="tokens-history-container">
  <div class="tokens-history-wrapper" [ngClass]="{ loading: tokenHistoryLoading() }">
    <div class="tokens-history-header-wrapper">
      <div class="tokens-header">
        <h2>Tokens history</h2>
      </div>
      <div class="header-content-wrapper">
        <div class="header-content-select">
          <span class="body-14 body-14--secondary">All</span>
          <fa-icon class="header-content-select--icon" [icon]="['fas', 'chevron-down']"></fa-icon>
        </div>
      </div>
    </div>

    <div class="tokens-history-content-container scrollbar scrollbar--overflow scrollbar--light" [ngClass]="{ 'tokens-history-content-container--scroll-active': tokenHistory.length > 8 }">
      <ng-container *ngFor="let history of tokenHistory()">
        <div class="tokens-history-content-wrapper">
          <div class="content-circle">
            <div class="circle">
              <img *ngIf="history.transactionType === TransactionType.TokenSale" class="token-icon" src="assets/icons/draftzy-icon.svg" alt="T" />
            </div>
          </div>

          <div class="content-info">
            <span class="body-16 body-16--secondary">{{ history.description }}</span>
            <span class="body-12">{{ history.date | date: 'mediumDate' }}</span>
          </div>

          <div class="content-amounts-wrapper">
            <div class="content-amounts">
              <div class="content-amounts--plus">
                <span class="body-12 body-12--white bold">+{{ history.amount | shortNumber }}</span>
              </div>
              <span class="body-12 self-end">{{ history.totalDzySaleBalance | shortNumber }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!tokenHistoryLoading() && tokenHistory().length === 0"> No results</ng-container>
    </div>
  </div>
</div>
