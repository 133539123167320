<app-coming-soon-card-overlay class="coming-soon-overlay z-index--1"></app-coming-soon-card-overlay>
<div class="dashboard-card-container">
  <div class="header-wrapper">
    <h2>Points</h2>
    <div class="league-wrapper">
      <div class="league-select">
        <span class="body-14 body-14--secondary">Premier League</span>
        <fa-icon class="league-select--icon" [icon]="['fas', 'chevron-down']"></fa-icon>
      </div>
    </div>
  </div>

  <div *ngFor="let fixture of fixtures; even as isEven">
    <div class="list-container" [ngClass]="{ 'bg--white-grey': isEven }">
      <div class="list-item">
        <span class="body-16 body-16--secondary name">{{ fixture.name }}</span>

        <div class="list-item--container">
          <div class="count-wrapper">
            <div class="count-wrapper--item">
              <img class="count-wrapper--item_img" src="assets/icons/draftzy-icon.svg" alt="T" />
              <span class="body-14 body-14--secondary bold">{{ fixture.points | shortNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-total-points-wrapper [text]="'Fixture points scored:'" [points]="2500"></app-total-points-wrapper>
</div>
