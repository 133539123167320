<div class="root-container" [ngClass]="{ 'root-container--row': sidebarVisible }">
  <div *ngIf="sidebarVisible && screenWidth >= 1280" class="sidebar">
    <app-sidebar></app-sidebar>
  </div>

  <div class="root-wrapper">
    <div *ngIf="headerVisible" class="header">
      <app-header></app-header>
    </div>
    <div class="root-content scrollbar">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
