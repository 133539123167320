import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from 'src/app/services/register.service';

@Component({
  selector: 'app-register-verify',
  templateUrl: './register-verify.component.html',
  styleUrls: ['./register-verify.component.scss']
})
export class RegisterVerifyComponent implements OnInit {
  externalId: string | null = null;
  loadingPage = true;
  verifySuccess = false;
  success = new FormControl({ value: false, disabled: true });

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.externalId = this.route.snapshot.paramMap.get('externalId');
    if (this.externalId != null) {
      this.registrationService.verifyRegistration(this.externalId).subscribe(result => {
        if (result) {
          this.verifySuccess = true;
          setTimeout(() => {
            this.success.setValue(true);
          }, 500);
        }
        this.loadingPage = false;
      });
    }
  }
}
