import { Component } from '@angular/core';

@Component({
  selector: 'app-session-expired-page',
  templateUrl: './session-expired-page.component.html',
  styleUrls: ['./session-expired-page.component.scss']
})
export class SessionExpiredPageComponent {

}
