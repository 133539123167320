<div class="session-expired-container">
  <div class="session-expired-header mb-24">
    <h3>Session expired</h3>
    <span class="body-14">Your session has expired, please login again to continue using Draftzy!</span>
  </div>

  <div class="session-expired-body">
    <fa-icon class="failed-wrapper failed-icon" [icon]="['fas', 'circle-exclamation']"></fa-icon>
  </div>
  <div class="session-expired-actions">
    <div class="session-expired-actions--login">
      <button class="button button--primary" routerLink="/login">
        <span>Go to login</span>
      </button>
    </div>
  </div>

  <div class="session-expired-footer">
    <span class="body-14 body-14--light">Not a member yet?</span>
    <span routerLink="/register" class="text-button--primary">Create an account</span>
  </div>
</div>
