import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => this.handleError(request.url, error)));
  }

  private handleError(requestUrl: string, error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if (!requestUrl.includes('/login') && error?.status === 401) {
      this.authService.logout();
      return EMPTY;
    }
    return throwError(() => error);
  }
}
