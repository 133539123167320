import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-points-wrapper',
  templateUrl: './total-points-wrapper.component.html',
  styleUrls: ['./total-points-wrapper.component.scss']
})
export class TotalPointsWrapperComponent {
  @Input() points = 0;
  @Input() text = '';
}
