import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [group([query('@appearAnimation', animateChild()), style({ width: 0 }), animate('500ms ease-out', style({ width: '*' }))])]),
      transition(':leave', [group([query('@appearAnimation', animateChild()), style({ width: '*' }), animate('500ms ease-in', style({ width: 0 }))])])
    ]),
    trigger('appearAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('400ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  screenWidth = 0;
  sidebarHamburgerOpen = false;
  hamburgerPreventCount = 0;
  username = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.username = this.authService.username ?? '';
  }

  setHamburgerStatus(open: boolean): void {
    this.hamburgerPreventCount++;
    if (this.hamburgerPreventCount % 2 === 0) {
      return;
    }

    this.sidebarHamburgerOpen = open;
  }

  closeHamburger(): void {
    if (this.sidebarHamburgerOpen) {
      const element = this.document.getElementById('burger-label');
      element != null ? element.click() : null;
    }
  }

  logout(): void {
    this.authService.logout();
  }

  goToTokens(): void {
    this.router.navigateByUrl('/tokens');
  }
}
