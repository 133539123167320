import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DzyTokenHistory } from '../models/tokens/dzy-token-history';

@Injectable({
  providedIn: 'root'
})
export class TokensHistoryService {
  private _tokenHistory = signal<DzyTokenHistory[]>([]);
  public tokenHistory = this._tokenHistory.asReadonly();
  private _loading = signal<boolean>(true);
  public loading = this._loading.asReadonly();

  constructor(private httpClient: HttpClient) {}

  getTokenHistory(): void {
    this._loading.set(true);

    this.httpClient
      .get<DzyTokenHistory[]>(`${environment.registrationApiUrl}/dzytokens/history`)
      .pipe(
        catchError(() => of([])),
        map(result => {
          this._tokenHistory.set(result);
          this._loading.set(false);
        })
      )
      .subscribe();
  }
}
