import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordResetRequest } from 'src/app/models/password-reset/password-reset-request';
import { PasswordResetService } from 'src/app/services/password-reset.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form!: FormGroup;
  email = new FormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' });
  success = new FormControl({ value: false, disabled: true });

  error = false;
  showSuccess = false;
  loading = false;

  constructor(private passwordResetService: PasswordResetService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: this.email
    });
  }

  getErrorMessage(): string {
    if (this.email?.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email?.hasError('email') ? 'Not a valid email' : '';
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.error = false;
      this.loading = true;

      const model = new PasswordResetRequest({ email: this.email.value ?? '' });
      this.passwordResetService.requestPasswordReset(model).subscribe(result => {
        if (result) {
          this.showSuccess = true;
          setTimeout(() => {
            this.success.setValue(true);
          }, 500);
        } else {
          this.error = true;
        }
        this.loading = false;
      });
    }
  }
}
