import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  username = '';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.username = this.authService.username ?? '';
  }
}
