<div class="dashboard-page-container">
  <div class="username-container">
    <h1>Hey, {{ username }} - Welcome to Draftzy!</h1>
  </div>
  <div class="flex flex--100">
    <div class="cards-container">
      <app-dashboard-card class="card"></app-dashboard-card>
      <app-dashboard-card-points class="card"></app-dashboard-card-points>
      <app-dashboard-card-leaderboard class="card"></app-dashboard-card-leaderboard>
    </div>
  </div>
</div>
