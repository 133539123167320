<form class="reset-password-container" [formGroup]="form">
  <div class="reset-password-header mb-24">
    <h3 *ngIf="!showSuccess">Reset password</h3>
    <h3 *ngIf="showSuccess">Reset link sent</h3>
    <span *ngIf="!showSuccess" class="body-14">Please enter your e-mail address below to receive a password reset link</span>
    <span *ngIf="showSuccess" class="body-14">Check your e-mail for a password reset link to complete your password reset</span>
  </div>
  <div class="reset-password-body">
    <app-input *ngIf="!showSuccess" [controlName]="email" [label]="'E-mail'" [placeholder]="'E-mail'"></app-input>
    <app-checkbox *ngIf="showSuccess" class="checkbox-animate" [controlName]="success" [big]="true"></app-checkbox>
  </div>

  <div class="reset-password-actions">
    <div class="reset-password-actions--reset">
      <button *ngIf="!showSuccess" class="button button--primary" [ngClass]="{ loading: loading }" type="button" (click)="submit()">
        <span *ngIf="!loading">Reset password</span>
      </button>
      <span *ngIf="!showSuccess && error" class="error mt-12">Something went wrong, please try again</span>

      <button *ngIf="showSuccess" class="button button--primary" routerLink="/login">
        <span>Back to login</span>
      </button>
    </div>
  </div>

  <div class="reset-password-footer">
    <span class="body-14 body-14--light">Not a member yet?</span>
    <span routerLink="/register" class="text-button--primary">Create an account</span>
  </div>
</form>
