import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InputType } from 'src/app/enums/input-type';
import { RegisterUser } from 'src/app/models/register/register-user';
import { RegistrationService } from 'src/app/services/register.service';
import { confirmPasswordValidator } from 'src/app/validators/confirm-password-validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  InputType = InputType;
  form!: FormGroup;
  username = new FormControl('', { validators: [Validators.required], updateOn: 'blur' });
  email = new FormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' });
  password = new FormControl('', { validators: [Validators.required, Validators.minLength(8)] });
  confirmPassword = new FormControl('', { validators: [Validators.required, Validators.minLength(8)] });
  terms = new FormControl(false, [Validators.requiredTrue]);

  loading = false;
  error = false;

  constructor(
    private registerService: RegistrationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup(
      {
        username: this.username,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        terms: this.terms
      },
      { validators: [confirmPasswordValidator] }
    );
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.loading = true;

      this.registerService.registerUser(this.getRegisterUser()).subscribe(result => {
        result ? this.router.navigateByUrl('/register/success') : this.router.navigateByUrl('/register/failed');
      });
    }
  }

  private getRegisterUser(): RegisterUser {
    return new RegisterUser({ username: this.username?.value ?? '', email: this.email?.value ?? '', password: this.password?.value ?? '' });
  }
}
