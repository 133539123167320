<div class="header-container">
  <div class="header-static-wrapper">
    <div *ngIf="screenWidth < 1280 && screenWidth >= 500" class="logo-wrapper">
      <img class="logo" src="assets/draftzy_icon.svg" alt="Logo" />
      <span class="header-title">Draftzy</span>
    </div>
    <app-user-balance class="balance"></app-user-balance>
    <div *ngIf="screenWidth >= 1280" class="divider--header"></div>
    <button *ngIf="screenWidth >= 1280" class="button button--primary" type="button" (click)="goToTokens()">
      <span>Get tokens</span>
    </button>
    <div *ngIf="screenWidth >= 1280" class="divider--header"></div>
    <div *ngIf="screenWidth >= 1280" class="body-16 username">{{ username }}</div>
    <div *ngIf="screenWidth < 1280" class="cross menu--1">
      <label id="burger-label" (click)="setHamburgerStatus(!sidebarHamburgerOpen)" (keydown)="setHamburgerStatus(!sidebarHamburgerOpen)" role="none">
        <input type="checkbox" />
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="30" />
          <path class="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
          <path class="line--2" d="M0 50h70" />
          <path class="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
        </svg>
      </label>
    </div>
  </div>
</div>

<div *ngIf="sidebarHamburgerOpen" class="sidebar-mobile-container" [@inOutAnimation]>
  <div [@inOutAnimation] class="menu">
    <div class="sidebar--body">
      <div class="sidebar--body--page" [@appearAnimation] routerLink="/home" routerLinkActive="active" (click)="closeHamburger()" (keydown)="closeHamburger()" role="none">
        <div class="icon icon--home" routerLinkActive="icon--home--active"></div>
        <span>Dashboard</span>
        <div class="fa-wrapper">
          <fa-icon class="fa--icon" [icon]="['fas', 'arrow-right-long']" routerLinkActive="fa--icon__active"></fa-icon>
        </div>
      </div>
      <div class="sidebar--body--page" [@appearAnimation] routerLink="/tokens" routerLinkActive="active" (click)="closeHamburger()" (keydown)="closeHamburger()" role="none">
        <div class="icon icon--tokens" routerLinkActive="icon--tokens--active"></div>
        <span>Tokens</span>
        <div class="fa-wrapper">
          <fa-icon class="fa--icon" [icon]="['fas', 'arrow-right-long']" routerLinkActive="fa--icon__active"></fa-icon>
        </div>
      </div>
      <div class="sidebar--body--page" [@appearAnimation] routerLink="/leagues" routerLinkActive="active" (click)="closeHamburger()" (keydown)="closeHamburger()" role="none">
        <div class="icon icon--leagues" routerLinkActive="icon--leagues--active"></div>
        <span>Leagues</span>
        <div class="fa-wrapper">
          <fa-icon class="fa--icon" [icon]="['fas', 'arrow-right-long']" routerLinkActive="fa--icon__active"></fa-icon>
        </div>
      </div>
      <div class="sidebar--body--page" [@appearAnimation] routerLink="/support" routerLinkActive="active" (click)="closeHamburger()" (keydown)="closeHamburger()" role="none">
        <div class="icon icon--support" routerLinkActive="icon--support--active"></div>
        <span>Support</span>
        <div class="fa-wrapper">
          <fa-icon class="fa--icon" [icon]="['fas', 'arrow-right-long']" routerLinkActive="fa--icon__active"></fa-icon>
        </div>
      </div>
      <div class="sidebar--body--page" [@appearAnimation] (click)="logout()" (keydown)="logout()" role="none">
        <div class="icon icon--logout"></div>
        <span>Logout</span>
        <div class="fa-wrapper">
          <fa-icon class="fa--icon" [icon]="['fas', 'arrow-right-long']"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
