import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/login/register/register.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { InputComponent } from './components/forms/input/input.component';
import { CheckboxComponent } from './components/forms/checkbox/checkbox.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { CirclesBackgroundComponent } from './components/background/circles-background/circles-background.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './components/login/reset-password-confirm/reset-password-confirm.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ResetPasswordConfirmPageComponent } from './pages/reset-password-confirm-page/reset-password-confirm-page.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { TokensPageComponent } from './pages/tokens-page/tokens-page.component';
import { SupportPageComponent } from './pages/support-page/support-page.component';
import { IconChevronUpComponent } from './components/icons/icon-chevron-up/icon-chevron-up.component';
import { IconChevronDownComponent } from './components/icons/icon-chevron-down/icon-chevron-down.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RegisterVerifyPageComponent } from './pages/register-verify-page/register-verify-page.component';
import { RegisterVerifyComponent } from './components/login/register-verify/register-verify.component';
import { RegisterResultComponent } from './components/login/register-result/register-result.component';
import { RegisterResultPageComponent } from './pages/register-result-page/register-result-page.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { DashboardCardComponent } from './components/dashboard/dashboard-card/dashboard-card.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightLong, faCheck, faChevronDown, faCircleExclamation, faCopy, faFutbol, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DashboardCardPointsComponent } from './components/dashboard/dashboard-card-points/dashboard-card-points.component';
import { DashboardCardLeaderboardComponent } from './components/dashboard/dashboard-card-leaderboard/dashboard-card-leaderboard.component';
import { UserBalanceComponent } from './components/user/user-balance/user-balance.component';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { ComingSoonCardOverlayComponent } from './components/dashboard/coming-soon-card-overlay/coming-soon-card-overlay.component';
import { TotalPointsWrapperComponent } from './components/shared/points/total-points-wrapper/total-points-wrapper.component';
import { TotalPointsCirclesBackgroundComponent } from './components/shared/points/total-points-circles-background/total-points-circles-background.component';
import { LeaguesComponent } from './components/leagues/leagues/leagues.component';
import { TokensComponent } from './components/tokens/tokens/tokens.component';
import { SupportComponent } from './components/support/support/support.component';
import { LeaguesPageComponent } from './pages/leagues-page/leagues-page.component';
import { SessionExpiredPageComponent } from './pages/session-expired-page/session-expired-page.component';
import { SessionExpiredComponent } from './components/login/session-expired/session-expired.component';
import { SaleTransactionCompletedDialogComponent } from './components/tokens/dialogs/sale-transaction-completed-dialog/sale-transaction-completed-dialog.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { TokensHistoryComponent } from './components/tokens/tokens-history/tokens-history.component';
import { TokenSalePageComponent } from './pages/token-sale-page/token-sale-page.component';
import { TokenSaleComponent } from './components/tokens/token-sale/token-sale.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LoginPageComponent,
    InputComponent,
    CheckboxComponent,
    RegisterPageComponent,
    CirclesBackgroundComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent,
    ResetPasswordPageComponent,
    ResetPasswordConfirmPageComponent,
    SidebarComponent,
    FooterComponent,
    HeaderComponent,
    DashboardPageComponent,
    TokensPageComponent,
    SupportPageComponent,
    LeaguesPageComponent,
    IconChevronUpComponent,
    IconChevronDownComponent,
    RegisterVerifyPageComponent,
    RegisterVerifyComponent,
    RegisterResultComponent,
    RegisterResultPageComponent,
    DashboardCardComponent,
    DashboardCardPointsComponent,
    DashboardCardLeaderboardComponent,
    UserBalanceComponent,
    ComingSoonCardOverlayComponent,
    TotalPointsWrapperComponent,
    TotalPointsCirclesBackgroundComponent,
    LeaguesComponent,
    TokensComponent,
    SupportComponent,
    SessionExpiredPageComponent,
    SessionExpiredComponent,
    SaleTransactionCompletedDialogComponent,
    ShortNumberPipe,
    TokensHistoryComponent,
    TokenSalePageComponent,
    TokenSaleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatDialogModule,
    ClipboardModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faFutbol, faUsers, faChevronDown, faArrowRightLong, faCircleExclamation, faCopy, faCheck);
  }
}
