import { Component } from '@angular/core';

@Component({
  selector: 'app-coming-soon-card-overlay',
  templateUrl: './coming-soon-card-overlay.component.html',
  styleUrls: ['./coming-soon-card-overlay.component.scss']
})
export class ComingSoonCardOverlayComponent {

}
