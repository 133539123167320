import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegisterUser } from '../models/register/register-user';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private httpClient: HttpClient) {}

  registerUser(registerUser: RegisterUser): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.registrationApiUrl}/registration`, registerUser).pipe(
      catchError(() => of(false)),
      map(result => result)
    );
  }

  verifyRegistration(externalId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.registrationApiUrl}/registration/verify/${externalId}`).pipe(
      catchError(() => of(false)),
      map(result => result)
    );
  }
}
