<div class="total-container">
  <div class="total-wrapper">
    <span class="body-16 body-16--white">{{ text }}</span>
    <div class="points-wrapper">
      <img class="points-icon" src="assets/icons/draftzy-icon.svg" alt="T" />
      <span class="body-18 body-18--secondary bold">{{ points | shortNumber }}</span>
    </div>
  </div>
  <app-total-points-circles-background class="total-points-circle-background"></app-total-points-circles-background>
</div>
