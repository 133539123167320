<div class="elements">
  <div class="circle-topleft">
    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
      <circle opacity="0.1" cx="64" cy="64" r="54" stroke="white" stroke-width="20" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
      <circle cx="19" cy="19" r="19" fill="white" fill-opacity="0.3" />
    </svg>
  </div>
  <div class="circle-middle">
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
      <circle cx="23" cy="23" r="23" fill="white" fill-opacity="0.25" />
    </svg>
  </div>
  <div class="circle-middletop">
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
      <circle cx="24" cy="10" r="24" fill="white" fill-opacity="0.15" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
      <circle cx="14.2529" cy="14" r="10.5" stroke="white" stroke-opacity="0.2" stroke-width="7" />
    </svg>
  </div>
  <div class="circle-middlebottom">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle opacity="0.1" cx="10" cy="10" r="10" fill="white" />
    </svg>
  </div>
  <div class="circle-topright">
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
      <circle cx="39" cy="39" r="33" stroke="white" stroke-opacity="0.2" stroke-width="12" />
    </svg>
  </div>
  <div class="circle-middleright">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <circle opacity="0.2" cx="25" cy="25" r="25" fill="white" />
    </svg>
  </div>
</div>
