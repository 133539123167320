<form class="login-container" [formGroup]="form">
  <div class="login-header">
    <h3>Welcome to Draftzy</h3>
  </div>
  <div class="login-body">
    <app-input [controlName]="email" [label]="'E-mail'" [placeholder]="'E-mail'"></app-input>
    <app-input [controlName]="password" [label]="'Password'" [placeholder]="'********'" [inputType]="InputType.Password"></app-input>
  </div>

  <div class="login-actions">
    <div class="login-actions--options">
      <div class="login-actions--options--remember">
        <app-checkbox [controlName]="remember" [label]="'Remember me'"></app-checkbox>
      </div>
      <div class="login-actions--options--forgot">
        <span routerLink="/resetpassword" class="text-button--primary">Forgot password</span>
      </div>
    </div>
    <div class="login-actions--signin">
      <button class="button button--primary" [ngClass]="{ loading: loadingLogin }" type="button" (click)="login()">
        <span *ngIf="!loadingLogin">Sign in</span>
      </button>
      <span *ngIf="loginError" class="error login-error body-14 body-14--error"> Something went wrong, please try again. </span>
    </div>
  </div>

  <div class="login-footer">
    <span class="body-14 body-14--light">Not a member yet?</span>
    <span routerLink="/register" class="text-button--primary">Create an account</span>
  </div>
</form>
