import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() label: string | undefined;
  @Input() controlName!: FormControl;
  @Input() big = false;
  @Input() showsTermsAndPrivacy = false;

  termsUrl = environment.termsUrl;
  termsLabel = 'Terms & Conditions';
  privacyUrl = environment.privacyUrl;
  privacyLabel = 'Privacy Policy';

  getErrorMessage(): string {
    if (this.controlName?.hasError('required')) {
      return 'This field is required';
    }

    return '';
  }
}
