import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { lastValueFrom } from 'rxjs';

export const authGuard: CanActivateFn = async () => {
  const authService: AuthService = inject(AuthService);

  const valid = await lastValueFrom(authService.validateToken());
  if (authService.token != null && valid) {
    return true;
  } else {
    authService.logout();
    return false;
  }
};
