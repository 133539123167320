<div *ngIf="!loadingPage" class="register-verify-container">
  <div class="register-verify-header mb-24">
    <h3 *ngIf="verifySuccess">Verification completed</h3>
    <h3 *ngIf="!verifySuccess">Verification has expired</h3>
    <span *ngIf="verifySuccess" class="body-14">Your email has been verified successfully. Use the login button below to get started!</span>
    <span *ngIf="!verifySuccess" class="body-14">This verification link has already been used and is not valid anymore.</span>
  </div>
  <div *ngIf="verifySuccess" class="register-verify-body">
    <app-checkbox class="checkbox-animate" [controlName]="success" [big]="true"></app-checkbox>
  </div>

  <div *ngIf="verifySuccess" class="register-verify-actions">
    <div class="register-verify-actions--reset">
      <button class="button button--primary" routerLink="/login">
        <span>Go to login</span>
      </button>
    </div>
  </div>

  <div *ngIf="!verifySuccess" class="register-verify-body">
    <fa-icon class="checkbox-animate failed-icon" [icon]="['fas', 'circle-exclamation']"></fa-icon>
  </div>
  <div *ngIf="!verifySuccess" class="register-verify-actions">
    <div class="register-verify-actions--reset">
      <button class="button button--primary" routerLink="/login">
        <span>Go to login</span>
      </button>
    </div>
  </div>

  <div class="register-verify-footer">
    <span class="body-14 body-14--light">Not a member yet?</span>
    <span routerLink="/register" class="text-button--primary">Create an account</span>
  </div>
</div>
<div *ngIf="loadingPage" class="register-verify-container loading"></div>
