<app-coming-soon-card-overlay class="coming-soon-overlay z-index--1"></app-coming-soon-card-overlay>
<div class="dashboard-card-container">
  <h2>Leagues</h2>
  <div *ngFor="let league of leagues; even as isEven">
    <div class="list-container" [ngClass]="{ 'bg--white-grey': isEven }">
      <div class="list-item">
        <fa-icon class="list-item--icon" [icon]="['fas', 'futbol']"></fa-icon>
        <span class="body-16 body-16--secondary name">{{ league.name }}</span>

        <div class="list-item--container">
          <div class="count-wrapper">
            <div class="count-wrapper--item">
              <fa-icon class="count-wrapper--item_icon" [icon]="['fas', 'users']"></fa-icon>
              <span class="body-14 body-14--secondary bold">{{ league.playerCount | shortNumber }}</span>
            </div>
            <div class="count-wrapper--item">
              <img class="count-wrapper--item_img" src="assets/icons/draftzy-icon.svg" alt="T" />
              <span class="body-14 body-14--secondary bold">{{ league.points | shortNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
