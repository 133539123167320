import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InputType } from 'src/app/enums/input-type';
import { LoginUser } from 'src/app/models/login/login-user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  InputType = InputType;
  form!: FormGroup;
  email = new FormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' });
  password = new FormControl('', { validators: [Validators.required, Validators.minLength(8)], updateOn: 'blur' });
  remember = new FormControl('');

  loginError = false;
  loadingLogin = false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.token != null) this.goToHome();
    this.form = new FormGroup({
      email: this.email,
      password: this.password,
      remember: this.remember
    });
  }

  getErrorMessage(): string {
    if (this.email?.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email?.hasError('email') ? 'Not a valid email' : '';
  }

  login(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.loadingLogin = true;
      this.loginError = false;
      this.authService.login(this.getLoginUser()).subscribe(token => {
        if (token != null) {
          this.goToHome();
        } else {
          this.loginError = true;
          this.loadingLogin = false;
        }
      });
    }
  }

  private getLoginUser(): LoginUser {
    return new LoginUser({
      email: this.email?.value ?? '',
      password: this.password?.value ?? ''
    });
  }

  private goToHome(): void {
    this.router.navigateByUrl('/home');
  }
}
