import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  leagueChevronDown = '--primary-text';

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.leagueChevronDown = this.router.url.toLowerCase().startsWith('/leagues') ? '--white' : '--primary-text';

    this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.leagueChevronDown = x.urlAfterRedirects.toLowerCase().startsWith('/leagues') ? '--white' : '--primary-text';
      }
    });
  }

  logout(): void {
    this.authService.logout();
  }

  onFocus(): void {
    return;
  }
  onBlur(): void {
    return;
  }
}
